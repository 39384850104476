var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"bg-variant":"Default"}},[_c('div',{staticClass:"d-flex flex-row bd-highlight pb-2"},[_c('b-button',{staticClass:"mr-1 flex-md-grow-1",attrs:{"variant":"outline-primary","to":{ name: 'azienda-leads-in-attesa' }}},[_vm._v("In Attesa ")]),_c('b-button',{staticClass:"mr-1 flex-md-grow-1",attrs:{"variant":"outline-primary","to":{ name: 'azienda-leads' }}},[_vm._v("In Corso ")]),_c('b-button',{staticClass:"mr-1 flex-md-grow-1",attrs:{"variant":"primary","to":{ name: 'azienda-leads-conslusi' }}},[_vm._v("Concluse ")]),_c('b-button',{staticClass:"ml-md-4 flex-md-grow-1",attrs:{"variant":"success","to":{ name: 'azienda-leads-nuovo'}}},[_vm._v("Nuova Richiesta ")])],1),_c('vue-good-table',{attrs:{"mode":"remote","totalRows":_vm.totalRecords,"rows":_vm.rows,"columns":_vm.columns,"isLoading":_vm.isLoading,"search-options":{
        enabled: true,
        externalQuery: _vm.searchTerm },"pagination-options":{
        enabled: true,
        mode: 'pages',
        nextLabel: 'successiva',
        prevLabel: 'precedente',
        rowsPerPageLabel: 'righe per pagina',
        ofLabel: 'di',
        pageLabel: 'pagina', // for 'pages' mode
        perPage: 10
    },"styleClass":"vgt-table"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'numero_ordine')?_c('div',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(props.row.numero_ordine)+" ")]),_c('br'),_c('span',{staticClass:"font-small-2 text-muted"},[_vm._v(" di "+_vm._s(props.row.data_inserimento)+" ")])]):(props.column.field === 'action')?_c('span',[_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"cursor-pointer mr-1",attrs:{"id":("tabellaordini-riga-" + (props.row.id) + "-riepilogo"),"icon":"FileTextIcon","size":"21"},on:{"click":function($event){return _vm.$router.push({ name: 'azienda-lead-dettaglio', params: { id_lead: props.row.id }})}}}),_c('b-tooltip',{staticClass:"cursor-pointer",attrs:{"title":"riepilogo ordine","target":("tabellaordini-riga-" + (props.row.id) + "-riepilogo")}})],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('template',{slot:"loadingContent_stop"},[_c('div',{staticClass:"bg-primary"},[_vm._v(" animated loading ....")])]),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"text-center"},[_vm._v(" Nessun dato disponibile ")])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }